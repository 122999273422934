import { ApolloClient, ApolloLink, ApolloProvider as DefaultApolloProvider, type Operation } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { type FC, type PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { authLink } from './authLink';
import { cache } from './cache';
import { errorLink } from './errorLink';
import { httpLink, searchHttpLink } from './httpLink';
import { traceHeaderLink } from './traceHeaderLink';
import { wsLink } from './wsLink';

export const ApolloProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  // We can't leverage flags at this level because the ApolloProvider is a parent of the FeatureHubFlagProvider
  // (our flags depend on auth, which depends on apollo). So we have to provide the separate clients here and
  // choose the correct one in the places we run our queries.
  const client = useMemo(() => {
    const searchLink = ApolloLink.from([
      errorLink(navigate),
      authLink(getAccessTokenSilently),
      traceHeaderLink,
      searchHttpLink,
    ]);
    const searchWsLink = wsLink();
    const metadataLink = ApolloLink.from([errorLink(navigate), authLink(getAccessTokenSilently), httpLink]);

    const link = ApolloLink.split(
      operation => shouldUseSearchApi(operation),
      ApolloLink.split(operation => shouldUseWebsockets(operation), searchWsLink, searchLink),
      metadataLink
    );
    return new ApolloClient({
      link,
      cache,
      connectToDevTools: true,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'ignore',
          notifyOnNetworkStatusChange: true,
        },
        query: {
          errorPolicy: 'all',
          notifyOnNetworkStatusChange: true,
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    });
  }, [navigate, getAccessTokenSilently]);

  return <DefaultApolloProvider client={client}>{children}</DefaultApolloProvider>;
};

function shouldUseSearchApi(operation: Operation) {
  return operation.getContext().clientName === 'search';
}

function shouldUseWebsockets(operation: Operation) {
  const definition = getMainDefinition(operation.query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
}
