import { setContext } from '@apollo/client/link/context';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

type GetAuth0Token = Auth0ContextInterface['getAccessTokenSilently'];

export function authLink(getTokenSilently: GetAuth0Token) {
  return setContext(async () => {
    const { access_token } = await getTokenSilently({
      detailedResponse: true,
    });

    return {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
  });
}
