import { CssBaseline, Grid2 } from '@mui/material';
import { type FC, lazy, StrictMode, Suspense } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { ApolloProvider } from 'core/Apollo/ApolloProvider';
import { Auth } from 'core/Auth/Auth';
import { AuthRoute } from 'core/Auth/AuthRoute';
import { Notifications } from 'shared/layers/Notification/Notifications';
import { ConfigProvider } from 'shared/utils/ConfigProvider/ConfigProvider';
import { ErrorBoundary } from 'shared/utils/ErrorBoundary/ErrorBoundary';

import { FeatureHubFlagProvider } from 'core/flags/FeatureHubFlagProvider';
import { LoadingSpinner } from 'shared/elements/LoadingSpinner/LoadingSpinner';
import { NotificationsSnackbar } from 'shared/layers/Notification/NotificationsSnackbar';

const App = lazy(() => import('./App/App'));
const AuditLogs = lazy(() => import('./App/AuditLogs/AuditLogs'));
const Login = lazy(() => import('./App/Login/Login'));
const Welcome = lazy(() => import('./App/Welcome/WelcomeContainer'));

// TODO: Remove this once we switch to pigment because this will become irrelevant.
// MUI's use of emotion will pick up this cache provider and use it rather than creating its own.
// This lets us configure the speedy option to true, improving performance when running locally by
// appending styles via the CSSOM rather than adding them DOM style tags. This mode is the default
// in production, so this also means our local runs a bit more like it does in the wild.
// The speedy option is defined here: https://www.npmjs.com/package/@emotion/sheet#speedy
const emotionCache = createCache({
  key: 'css',
  prepend: true,
  speedy: true,
});

export const Root: FC = () => {
  return (
    <StrictMode>
      <CacheProvider value={emotionCache}>
        <Auth0Provider
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          authorizationParams={{
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin,
          }}
          cacheLocation="localstorage"
          useRefreshTokens
        >
          <FeatureHubFlagProvider>
            <IntercomProvider
              appId={import.meta.env.VITE_INTERCOM_ID}
              autoBoot={false}
              autoBootProps={{
                backgroundColor: '#13c2dd',
                hideDefaultLauncher: true,
              }}
              shouldInitialize={import.meta.env.isProd}
            >
              <BrowserRouter
                future={{
                  v7_relativeSplatPath: true,
                }}
              >
                {/* missing "encode: true" in options? */}
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <ConfigProvider>
                    <CssBaseline enableColorScheme />
                    <HelmetProvider>
                      <Helmet titleTemplate="%s | Query" defaultTitle="Query" />
                      <ApolloProvider>
                        <ErrorBoundary>
                          <Notifications>
                            <Auth>
                              <Suspense
                                fallback={
                                  <Grid2
                                    container
                                    sx={{
                                      height: '100vh',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <LoadingSpinner size="large" />
                                  </Grid2>
                                }
                              >
                                <Routes>
                                  <Route path="login" element={<Login />} />
                                  <Route path="welcome" element={<AuthRoute element={<Welcome />} />} />
                                  <Route path="audit" element={<AuthRoute element={<AuditLogs />} />} />
                                  <Route path="/*" element={<AuthRoute element={<App />} />} />
                                </Routes>
                              </Suspense>
                            </Auth>
                            <NotificationsSnackbar />
                          </Notifications>
                        </ErrorBoundary>
                      </ApolloProvider>
                    </HelmetProvider>
                  </ConfigProvider>
                </QueryParamProvider>
              </BrowserRouter>
            </IntercomProvider>
          </FeatureHubFlagProvider>
        </Auth0Provider>
      </CacheProvider>
    </StrictMode>
  );
};
