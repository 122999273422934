import { Button, type ButtonOwnProps, type ButtonProps, SvgIcon, type SvgIconTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { forwardRef, type FunctionComponent } from 'react';

export type MuiButtonProps = Pick<
  ButtonProps,
  'children' | 'size' | 'disabled' | 'onClick' | 'variant' | 'sx' | 'color' | 'type'
> &
  Pick<ButtonOwnProps, 'endIcon'> & {
    icon?:
      | OverridableComponent<SvgIconTypeMap<object, 'svg'>>
      | FunctionComponent<
          React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
          }
        >;
    className?: string;
  };

// The button component is used for text buttons and icon + text buttons. For just icons, use the MuiIconButton component.
export const MuiButton = forwardRef<HTMLButtonElement, MuiButtonProps>(
  (
    {
      children,
      disabled,
      variant = 'contained',
      size,
      icon,
      onClick,
      sx,
      color,
      type,
      endIcon,
      className,
      ...rest
    }: MuiButtonProps,
    ref
  ) => (
    <Button
      disabled={disabled}
      variant={variant}
      size={size}
      onClick={onClick}
      sx={sx}
      color={color}
      type={type}
      endIcon={endIcon}
      startIcon={icon && <SvgIcon component={icon} inheritViewBox fontSize="inherit" />}
      className={className}
      ref={ref}
      {...rest}
    >
      {children}
    </Button>
  )
);
